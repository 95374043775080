import React from "react"
import { Link, graphql } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"
import { Breadcrumb, Typography, Card, Avatar, Button, Timeline } from "antd"
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import _orderBy from "lodash/orderBy"
import moment from "moment"

import Layout from "../../app/layout"

import Seo from "../../components/seo"
import Container from "../../components/Container"
import Masthead from "../../components/Masthead"

import { ChevronRight } from "../../components/Icon"

import "./pendaftaran-beasiswa.less"

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])

const PendaftaranBeasiswa = ({
  data: { registrationScheduling: data = {} },
}) => {
  const { t } = useI18next()
  const { Meta } = Card
  const { Title } = Typography
  const orderedRegistration = _orderBy(data.registration, ["order"], "asc")
  const today = new Date().getTime()

  const formatDate = dateString => {
    const dateMoment = moment(dateString)
    const monthTranslationKey = dateMoment.format("MMMM")
    const translatedMonth = t(monthTranslationKey)

    return `${dateMoment.format("DD")} ${translatedMonth} ${dateMoment.format(
      "YYYY"
    )}`
  }

  const goToNearest = () => {
    const currDate = new Date().getTime()
    const dateList = data.scheduling.reduce((acc, val) => {
      acc = [
        ...acc,
        ...val.schedule_list.map(d =>
          new Date(d.activity_start_date).getTime()
        ),
      ]

      return acc
    }, [])

    const selectedDate =
      dateList.find(d => d >= currDate) || dateList[dateList.length - 1]

    document.getElementById("anc-" + selectedDate).scrollIntoView({
      behavior: "smooth",
    })
  }

  return (
    <Layout module_name="scholarship" page_name="registration-scheduling">
      <Seo
        title="Pendaftaran & Penjadwalan"
        module_name="scholarship"
        page_name="registration-scheduling"
      />
      <Container>
        <Breadcrumb separator={<ChevronRight />}>
          <Breadcrumb.Item>
            <Link to="/">{t("Beranda")}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{data.page_setting.title}</Breadcrumb.Item>
        </Breadcrumb>
      </Container>

      <Masthead
        image={data.page_setting.banner_image}
        title={data.page_setting.title}
        textBottom={t("Lihat Selengkapnya")}
      />

      {data.registration.length > 0 && (
        <div className="section" style={{ overflow: "hidden" }}>
          <Container>
            <Title>{data.page_setting.registration_title}</Title>
            <Swiper
              modules={[Navigation]}
              width={320}
              spaceBetween={20}
              breakpoints={{
                360: {
                  width: 280,
                  slidesPerView: 1,
                },
                // when window width is >= 640px
                640: {
                  width: 300,
                  slidesPerView: 1,
                },
                // when window width is >= 768px
                768: {
                  width: null,
                  spaceBetween: 30,
                  slidesPerView: 2,
                },
              }}
              className="slider-registrasi"
              navigation={{
                nextEl: ".next",
                prevEl: ".prev",
                clickable: true,
              }}
            >
              {data.registration.length > 2 && (
                <div className="navigation">
                  <div className="prev">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.41 7.41L14 6L8 12L14 18L15.41 16.59L10.83 12L15.41 7.41Z"
                        fill="#FF6900"
                      />
                    </svg>
                  </div>
                  <div className="next">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.59 16.59L10 18L16 12L10 6L8.59 7.41L13.17 12L8.59 16.59Z"
                        fill="#FF6900"
                      />
                    </svg>
                  </div>
                </div>
              )}

              {orderedRegistration.map((item, i) => (
                <SwiperSlide key={i}>
                  <Card style={{ height: "100%" }}>
                    <Meta
                      avatar={<Avatar size={80} src={item.logo} />}
                      title={
                        <h3 style={{ fontSize: 16, marginBottom: 0 }}>
                          {item.title}
                        </h3>
                      }
                      description={
                        <>
                          <p style={{ marginBottom: 8 }}>
                            {item.short_description}
                          </p>
                          <p>
                            <strong>{t("Periode Pendaftaran")}</strong>{" "}
                            {t(moment(item.period_from_month).format("MMMM"))} -{" "}
                            {t(moment(item.period_to_month).format("MMMM"))}{" "}
                            {item.period_year}
                          </p>
                          <Button block size="small" href={item.link_url}>
                            {item.button_text}
                          </Button>
                        </>
                      }
                    />
                  </Card>
                </SwiperSlide>
              ))}
            </Swiper>
          </Container>
        </div>
      )}

      {data.scheduling.length > 0 && (
        <div className="section section-secondary text-medium">
          <Container>
            <div className="text-center">
              <h2 className="section-title">
                {data.page_setting.scheduling_title}
              </h2>
            </div>
            <div className="timeline-container">
              <button
                onClick={goToNearest}
                className="timeline-title timeline-title-red"
              >
                {t("Jadwal Saat Ini")}
              </button>
              {data.scheduling.map((item, i) => (
                <>
                  <h4 className="timeline-title" style={{ marginBottom: 0 }}>
                    {item.stage_name}
                  </h4>
                  <Timeline key={i} className="timeline">
                    {(item.schedule_list || []).map((list, index) => {
                      const outdated =
                        moment(
                          list.activity_end_date || list.activity_start_date
                        ).unix() *
                          1000 <
                        today

                      return (
                        <Timeline.Item key={index}>
                          <div
                            id={
                              "anc-" +
                              new Date(list.activity_start_date).getTime()
                            }
                            className={outdated ? "outdated-timeline" : ""}
                          >
                            <date>{formatDate(list.activity_start_date)}</date>
                            <p className="title">{list.activity_name}</p>
                            <p className="gelombang">{list.tags}</p>
                          </div>
                        </Timeline.Item>
                      )
                    })}
                    {i === data.scheduling.length - 1 && (
                      <Timeline.Item className="last-timeline" />
                    )}
                  </Timeline>
                </>
              ))}
            </div>
          </Container>
        </div>
      )}
    </Layout>
  )
}

export default PendaftaranBeasiswa

export const query = graphql`
  query registrationSchedulingQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    registrationScheduling(lang: { eq: $language }) {
      scheduling {
        stage_name
        schedule_list {
          activity_name
          stage_id
          tags
          activity_start_date
        }
      }
      registration {
        button_text
        link_url
        logo
        order
        period_from_month
        period_to_month
        period_year
        publish
        short_description
        title
      }
      page_setting {
        banner_image
        scheduling_title
        registration_title
        slug
        title
      }
    }
  }
`
